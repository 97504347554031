import React from "react";
import Container from "@cloudscape-design/components/container";
import { useState, useEffect, useRef } from "react";
import Table from "@cloudscape-design/components/table";
import { Auth } from "aws-amplify";


function compareEvents( a, b ) {
  if ( a.id < b.id ){
    return -1;
  }
  if ( a.id > b.id ){
    return 1;
  }
  return 0;
}

function WorkshopOverview(props) {
  
  const [events, setEvents] = useState();

  useEffect(() => {
    const getEvents = async () => {
      const res = await fetch(props.api+"overview",{
        headers: {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        }
      });
      const resJson = await res.json()

      setEvents(resJson.events.sort(compareEvents))
    }
    getEvents();
  },[]);

  return (
    <Container><h2>Overview</h2>
    <Table
      columnDefinitions={[
        {
          id: "id",
          header: "Workshop ID",
          cell: e => e.id,
        },
        {
          id: "name",
          header: "Workshop Name",
          cell: e => e.eventName
        },
        {
          id: "template",
          header: "Template",
          cell: e => e.template
        },
        { id: "open",
          header: "",
          cell: e => <button onClick={() => props.onEventSelected(e.id, e.template)}>Open</button>
        }
      ]}
      columnDisplay={[
        { id: "id", visible: true },
        { id: "name", visible: true },
        { id: "template", visible: true },
        { id: "open", visible: true}
      ]}
      contentDensity="compact"
      variant="embedded"
      items={events}
      loadingText="Loading resources"
      resizableColumns
    />
    </Container>
  );
}

export default WorkshopOverview;
