import React from "react";
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  StatusIndicator,
  SpaceBetween,
  Table,
  Modal,
  Link,
} from "@cloudscape-design/components";
import { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
//import Table from "@cloudscape-design/components/table";

function EventOverview(props) {
  const [templateData, setTemplateData] = useState();
  const [eventData, setEventData] = useState();
  const [loaded, setLoaded] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [detailModalVisible, setDetailsModalVisible] = React.useState(false);
  const [userFiles, setUserFiles] = useState([]);

  const SECTIONS_COLUMN_DEFINITIONS = [
    {
      id: "id",
      header: "Id",
      cell: (item) => item.id,
      isRowHeader: true,
    },
    {
      id: "name",
      header: "Name",
      cell: (item) => item.name,
    },
    {
      id: "status",
      header: "Status",
      cell: (item) => {
        console.log(Number(item.id), eventData.event.currentSection, eventData);
        if (Number(item.id) < eventData.event.currentSection) {
          return <StatusIndicator type="success">Completed</StatusIndicator>;
        }
        if (Number(item.id) == eventData.event.currentSection) {
          return (
            <StatusIndicator type="in-progress">In progress</StatusIndicator>
          );
        }
        if (Number(item.id) > eventData.event.currentSection) {
          return <StatusIndicator type="pending">Pending</StatusIndicator>;
        }
      },
    },
    {
      id: "action",
      header: "",
      cell: (item) => {
        if (
          Number(item.id) - 1 === eventData.event.currentSection &&
          eventData.event.status === "started"
        ) {
          return (
            <Button onClick={() => updateEvent("started", Number(item.id))}>
              Activate Section
            </Button>
          );
        }
      },
    },
  ];

  const PARTICIPANTS_COLUMN_DEFINITIONS = [
    {
      id: "id",
      header: "Id",
      cell: (item) => item.id,
      isRowHeader: true,
    },
    {
      id: "account",
      header: "AWS Account",
      cell: (item) => item.awsaccountid,
    },
    {
      id: "region",
      header: "Region",
      cell: (item) => item.awsregion,
    },
    {
      id: "desktop",
      header: "Desktop Status",
      cell: (item) => {
        if (item.desktopConnectionId === undefined) {
          return "-";
        } else {
          return <StatusIndicator type="success" />;
        }
      },
    },
    {
      id: "mobile",
      header: "Mobile Status",
      cell: (item) => {
        if (item.mobileConnectionId === undefined) {
          return "-";
        } else {
          return <StatusIndicator type="success" />;
        }
      },
    },
    {
      id: "actions",
      header: "Tools",
      cell: (item) => {
        return (
          <span>
            <Button onClick={() => getFiles(item.id)}>Details</Button>&nbsp;
            <Button>Open Account</Button>
          </span>
        );
      },
    },
  ];

  const S3_COLUMN_DEFINITIONS = [
    {
      id: "key",
      header: "Key",
      cell: (item) => item.Key,
      isRowHeader: true,
    },
    {
      id: "lastmodified",
      header: "LastModified",
      cell: (item) => item.LastModified,
    },
    {
      id: "original",
      header: "Original",
      cell: (item) => (
        <Link target="blank" external href={item.Original}>
          open
        </Link>
      ),
    },
    {
      id: "processed",
      header: "Processed",
      cell: (item) => (
        <Link target="blank" external href={item.Processed}>
          open
        </Link>
      ),
    },
  ];

  const getJson = async (url, options = {}) => {
    const res = await fetch(url, options);
    return await res.json();
  };

  const getFiles = async (userId) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      body: JSON.stringify({
        userId,
      }),
    };
    fetch(props.api + "userFiles", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUserFiles(data);
        setDetailsModalVisible(true);
      });
  };

  useEffect(() => {
    const getDetails = async () => {
      setTemplateData(
        await getJson(
          props.repo + "workshops/" + props.template + "/workshop.json"
        )
      );
      const eventRes = await getJson(props.api + "events/" + props.eventId, {
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
      });
      setEventData(eventRes);
      setLoaded(true);
    };
    getDetails();
  }, [refreshCounter]);

  const updateEvent = async (status, currentSection) => {
    setLoaded(false);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      body: JSON.stringify({
        status,
        currentSection,
      }),
    };
    fetch(props.api + "events/" + props.eventId, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setRefreshCounter(refreshCounter + 1);
      });
  };

  const countParticipants = (sessions, property) => {
    return sessions.filter((obj) => obj[property] !== undefined).length;
  };

  return loaded ? (
    <div>
      <Container
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  iconName="refresh"
                  variant="icon"
                  onClick={() => {
                    setLoaded(false);
                    setRefreshCounter(refreshCounter + 1);
                  }}
                />
                {eventData.event.status === "started" ? (
                  <Button
                    onClick={() =>
                      updateEvent("stopped", eventData.event.currentSection)
                    }
                  >
                    Stop Event
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      updateEvent("started", eventData.event.currentSection)
                    }
                  >
                    Start Event
                  </Button>
                )}
                {eventData.event.currentSection !== -1 &&
                eventData.event.status === "stopped" ? (
                  <Button onClick={() => updateEvent("stopped", -1)}>
                    Reset Event
                  </Button>
                ) : (
                  <span />
                )}
              </SpaceBetween>
            }
          >
            Event
          </Header>
        }
      >
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Name</Box>
              <div>{eventData.event.eventName}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">EventId</Box>
              <div>{props.eventId}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Created</Box>
              <div>{eventData.event.created}</div>
            </div>
          </SpaceBetween>

          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Status</Box>
              <div>
                <StatusIndicator
                  type={eventData.status === "started" ? "success" : "stopped"}
                >
                  {eventData.event.status === "started"
                    ? "In Progress"
                    : "Stopped"}
                </StatusIndicator>
              </div>
            </div>
            <div>
              <Box variant="awsui-key-label">Registered Participants</Box>
              <div>{eventData.sessions.length}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Active Participants</Box>
              <div>
                Desktop:{" "}
                {countParticipants(eventData.sessions, "desktopConnectionId")},
                Mobile:{" "}
                {countParticipants(eventData.sessions, "mobileConnectionId")}
              </div>
            </div>
          </SpaceBetween>
        </ColumnLayout>{" "}
      </Container>
      <br />
      <Table
        columnDefinitions={SECTIONS_COLUMN_DEFINITIONS}
        items={templateData.steps}
        header={
          <Header counter={"(" + templateData.steps.length + ")"}>
            Sections
          </Header>
        }
      />
      <br />
      <Table
        columnDefinitions={PARTICIPANTS_COLUMN_DEFINITIONS}
        items={eventData.sessions}
        header={
          <Header counter={"(" + eventData.sessions.length + ")"}>
            Participants
          </Header>
        }
      />
      <Modal
        onDismiss={() => setDetailsModalVisible(false)}
        visible={detailModalVisible}
        size="large"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="primary">Close</Button>
            </SpaceBetween>
          </Box>
        }
        header="Details"
      >
        <Table
          columnDefinitions={S3_COLUMN_DEFINITIONS}
          items={userFiles}
          variant="embedded"
        />
      </Modal>
    </div>
  ) : (
    <StatusIndicator type="loading">Loading... </StatusIndicator>
  );
}

export default EventOverview;
