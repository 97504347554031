import "@cloudscape-design/global-styles/index.css";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import WorkshopOverview from "./components/workshopOverview";
import EventOverview from "./components/eventOverview";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

const {
  API_ENDPOINT,
  CONTENT_REPO,
  REGION,
  USER_POOL_ID,
  USER_POOL_APP_CLIENT_ID,
} = window.config[/*"admin.builder.brickformation.wwps.aws.dev"*/window.location.hostname];

Amplify.configure({
  Auth: {
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_APP_CLIENT_ID,
  },
});

function App() {
  const [status, setStatus] = useState("start");
  const [eventId, setEventId] = useState();
  const [template, setTemplate] = useState();
  let content = <p />;

  const eventSelected = (id, template) => {
    setEventId(id);
    setTemplate(template);
    setStatus("eventOverview");
  };

  if (status === "start") {
    content = (
      <WorkshopOverview onEventSelected={eventSelected} api={API_ENDPOINT} />
    );
  }
  if (status === "eventOverview") {
    content = (
      <EventOverview
        eventId={eventId}
        template={template}
        api={API_ENDPOINT}
        repo={CONTENT_REPO}
      />
    );
  }

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <SpaceBetween size="m">
          <Header variant="h1">
            <img
              src="brickformation-logo.png"
              alt="logo"
              width="258"
              height="59"
            />{" "}
            - Facilitator Frontend
          </Header>
          {content}
        </SpaceBetween>
      )}
    </Authenticator>
  );
}

export default App;
